.App {
  /*font-family: Source Sans Pro;*/
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

legend {
  width: 0 !important;
}

.Container {
  margin-right: 352px;
}

.Content {
  flex-grow: 1;
  padding: 40px;
}

.ContentHeader {
  background: #eff5f8;
  padding: 24px 40px;
}

.HeaderRight {
  margin-left: auto;
}

.SidePanel {
  border-style: none none none solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.12);
  height: calc(100vh - 56px);
  width: 352px;
  position: absolute;
  top: 56px;
  right: 0;
}

.SidePanelContent {
  padding: 24px;
}

.SubHeader {
  display: flex;
}

.DetailsTabs {
  padding-left: 40px;
  background: #eff5f8;
}

.DraftEditor-root {
  padding: 16px 52px;
  cursor: text;
}

.draftJsToolbar__toolbar__dNtBH {
  background: #eff5f8;
  z-index: 2;
  padding: 12px;
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: none;
  border: 0;
  height: 34px;
  width: 36px;
  fill: #5e5f61;
  vertical-align: middle;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #89898a;
}
